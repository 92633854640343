import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Christmas trees sold in USA",
  "description": "Every year americans buy a bunch of christmas trees. Use the dataset to compare real and fake sales with two bar charts.",
  "date": "2018-12-03T08:00:00.000Z",
  "published": "2018-12-03T08:00:00.000Z",
  "image": "./christmastrees.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <lite-youtube {...{
      "videoid": "Blyq4m0CvxY",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`Every year americans buy a bunch of christmas trees. Use the dataset to compare real and fake sales with two bar charts.`}</p>
    <strong>Dataset: </strong>
    <a href={`./statistic_id209249_christmas-trees-sold-in-the-united-states-2004-2017.xlsx`}>
  Download dataset 🗳{" "}
    </a>
    <h2 {...{
      "id": "my-solution-"
    }}>{`My solution 👇`}</h2>
    <iframe {...{
      "src": "https://codesandbox.io/embed/p9p89w86wx",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "how-it-works-️"
    }}>{`How it works ⚙️`}</h2>
    <p>{`Christmas trees sold in USA is an example of a simple barchart built with React and D3. More React than D3 really. 😇`}</p>
    <p>{`We used the simplified full integration approach because the data never changes. Neither do any of the other props like `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{`. Means we don't have to worry about updating D3's internal state. Plopping D3 stuff into class field properties works great.`}</p>
    <p>{`We converted our dataset from xlsx into a tab separated values file. Easy copy paste job with this tiny dataset. That goes in `}<inlineCode parentName="p">{`/public/data.tsv`}</inlineCode>{`.`}</p>
    <p>{`To load the dataset we use `}<inlineCode parentName="p">{`d3.tsv`}</inlineCode>{` inside `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`  componentDidMount() {
    d3.tsv("/data.tsv", ({ year, real_trees, fake_trees }) => ({
      year: Number(year),
      real_trees: Number(real_trees),
      fake_trees: Number(fake_trees)
    })).then(data => this.setState({ data }));
  }
`}</code></pre>
    <p>{`When the `}<inlineCode parentName="p">{`<App>`}</inlineCode>{` component first loads, it makes a `}<inlineCode parentName="p">{`fetch()`}</inlineCode>{` request for our data file. D3 parses the file as a list of tab separated values and passes each line through our data parsing function. That turns it into a nicely formatted object with real numbers.`}</p>
    <p><inlineCode parentName="p">{`.then`}</inlineCode>{` we update component state with our data.`}</p>
    <p>{`Inside the render method we use a conditional. When data is present, we render a `}<inlineCode parentName="p">{`<Barchart>`}</inlineCode>{` component with a title. When there's no data, we render nothing.`}</p>
    <p>{`No need for a loading screen with a dataset this small. Loads and parses super fast. 👌`}</p>
    <h3 {...{
      "id": "render-the-emoji-barchart"
    }}>{`Render the emoji barchart`}</h3>
    <p><img parentName="p" {...{
        "src": "./christmastrees.gif",
        "alt": "Switchable emoji christmas tree barchart"
      }}></img></p>
    <p>{`We created a `}<inlineCode parentName="p">{`<Barchart>`}</inlineCode>{` component that takes:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`data`}</inlineCode>{`, our entire dataset`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`value`}</inlineCode>{`, the key name we're displaying`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`y`}</inlineCode>{`, the vertical position`}</li>
    </ul>
    <p>{`Final version doesn't need that vertical positioning param, but it's nice to have. You never know.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Barchart`}</inlineCode>{` uses a horizontal `}<inlineCode parentName="p">{`scaleBand`}</inlineCode>{` to handle each column's positioning. Scale bands are a type of ordinal scale. They automatically handle spacing, padding, and making sure our columns neatly fit into a given width.`}</p>
    <p>{`There's no height axis because we want each christmas tree emoji 🎄 to represent a million real life trees.`}</p>
    <p>{`We loop over the data and render a `}<inlineCode parentName="p">{`TreeBar`}</inlineCode>{` and a `}<inlineCode parentName="p">{`text`}</inlineCode>{` label for each entry.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<g transform={\`translate(0, \${y})\`}>
  {data.map(d => (
    <React.Fragment>
      <TreeBar x={this.xScale(d.year)} y={480} count={d[this.props.value]} />
      <text
        x={this.xScale(d.year)}
        y={500}
        style={{ strike: 'black', fontSize: '12px' }}
        textAnchor="center"
      >
        {d.year}
      </text>
    </React.Fragment>
  ))}
</g>
`}</code></pre>
    <p>{`A grouping element holds everything in place and creates a common coordinate system to work off of. It's like a div. We position it with a `}<inlineCode parentName="p">{`transform=translate`}</inlineCode>{` and elements inside position relatively to the group.`}</p>
    <p>{`For each iteration of our `}<inlineCode parentName="p">{`data`}</inlineCode>{`, we render a `}<inlineCode parentName="p">{`<TreeBar>`}</inlineCode>{` and a `}<inlineCode parentName="p">{`<text>`}</inlineCode>{`. Text goes at the bottom and displays the year. That's our label.`}</p>
    <p><inlineCode parentName="p">{`<TreeBar>`}</inlineCode>{` gets a horizontal position through our `}<inlineCode parentName="p">{`xScale`}</inlineCode>{`, a vertical position which is static, and a `}<inlineCode parentName="p">{`count`}</inlineCode>{` of how many trees 🎄 to show. We use `}<inlineCode parentName="p">{`this.props.value`}</inlineCode>{` to dynamically fetch the correct part of each data object.`}</p>
    <h3 {...{
      "id": "a-treebar-of-emojis-"
    }}>{`A `}{`<`}{`TreeBar> of emojis 🎄`}</h3>
    <p>{`Now here's a silly little fun part: Instead of an SVG rectangle, we build each bar from a bunch of emoji text elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const TreeBar = ({ x, y, count }) => (
  <g transform={\`translate(\${x}, \${y})\`}>
    {d3.range(count).map(i => (
      <text x={0} y={-i * 12} style={{ fontSize: '20px' }}>
        🎄
      </text>
    ))}
    <text
      x={0}
      y={-(count + 1) * 12 - 5}
      style={{ fontSize: '9px' }}
      textAnchor="center"
    >
      {count}
    </text>
  </g>
)
`}</code></pre>
    <p>{`Once more we start with a grouping element. That holds everything together.`}</p>
    <p>{`Then we create a fake array with `}<inlineCode parentName="p">{`d3.range`}</inlineCode>{` and iterate. For each index in the array, we return a text element positioned at a `}<inlineCode parentName="p">{`12px`}</inlineCode>{` offset from the previous element, a `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` of `}<inlineCode parentName="p">{`20px`}</inlineCode>{`, and a christmas tree emoji for content.`}</p>
    <p>{`We found the values through experiment. Keeping emojis spaced to their full height created bars that were too hard to read. An overlap works great. Keeps the bars readable and the emojis recognizable.`}</p>
    <p>{`That final little text shows how many million trees we drew in each bar. Makes our chart easier to read than counting tres :)`}</p>
    <h3 {...{
      "id": "what-you-learned-🧐"
    }}>{`What you learned 🧐`}</h3>
    <p>{`Today you learned:`}</p>
    <ul>
      <li parentName="ul">{`D3 band scales`}</li>
      <li parentName="ul">{`D3 range for creating iteration arrays`}</li>
      <li parentName="ul">{`using class field values for D3 objects`}</li>
      <li parentName="ul">{`a simple full integration approach`}</li>
    </ul>
    <p>{`React for rendering. D3 to help calculate props.`}</p>
    <p>{`✌️`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      